import * as React from "react"
import * as pageStyles from "../components/pageContent.module.css"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const StandardPage = ({ data }) => {
  const pageData = data.mysqlPages

  // console.log(data.mysqlPages.slug)

  return (
    <Layout
      splashPage={false}
      pageTitle={pageData.page_title}
      pageSlug={pageData.slug}
    >
      <Seo title={pageData.page_title} />

      <div className={pageStyles.pageText}>{pageData.page_title}</div>

      <div
        dangerouslySetInnerHTML={{
          __html: pageData.page_content,
        }}
      />
    </Layout>
  )
}

StandardPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query($PageId: Int!) {
    mysqlPages(mysqlId: { eq: $PageId }) {
      page_title
      slug
      mysqlId
      page_content
    }
  }
`

export default StandardPage
